/* global jQuery*/

jQuery(function($) {
  'use strict';

  if(!window.services) window.services = {};

  window.services.Common = {
    toQueryString: function(params) {
      return $.param(params);
    },
    call: function(method, url, data, cb) {
      if(!cb) {
        cb = data;
        data = null;
      }

      if (data && data.toQueryString) {
        delete data.toQueryString;
        url += '?' + this.toQueryString(data);
        data = null;
      }

      var ajaxConfig = {
        dataType: 'json',
        type: method,
        url: url,
        success: function(result) {
          if(result && result.error) {
            cb(result.error);
            return;
          }

          cb(null, result);
        },
        error: function(xhr, status, err) {
          if(err && err.statusText === 'timeout') {
            cb('Request timeout');
            return;
          }

          if(err && err.status === 413) {
            cb('Request too long');
            return;
          }

          var parsedError;
          try {
            var data = JSON.parse(xhr.responseText);
            parsedError = (data && data.error) ? data.error : xhr.responseText;
            if(parsedError.message)
              parsedError = parsedError.message;
          }
          catch(e) { /* nothing to check, return raw err if json parse failed */ }

          cb(parsedError || err || 'Unknown error');
        }
      };

      if(data && data.dataType) {
        ajaxConfig.dataType = data.dataType;
        delete data.dataType;
      }
      if(data && data.timeout) {
        ajaxConfig.timeout = data.timeout;
        delete data.timeout;
      }
      if(data && data.headers) {
        ajaxConfig.headers = data.headers;
        delete data.headers;
      }

      if(data) {
        if(typeof data !== 'string') {
          ajaxConfig.contentType = 'application/json';
          if(method === 'POST' || method === 'PUT' || method === 'DELETE')
            data = JSON.stringify(data);
        }
        ajaxConfig.data = data;
      }
      $.ajax(ajaxConfig);
    },
    post: function(url, data, cb) {
      this.call('POST', url, data, cb);
    },
    put: function(url, data, cb) {
      this.call('PUT', url, data, cb);
    },
    get: function(url, data, cb) {
      this.call('GET', url, data, cb);
    },
    delete: function(url, data, cb) {
      this.call('DELETE', url, data, cb);
    },
    getSync: function(url, data) {
      var response = $.ajax({
        type: 'GET',
        url: url,
        dataType: 'json',
        data: data,
        async: false
      }).responseText;

      return $.parseJSON(response);
    }
  };
});
